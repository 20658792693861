'use strict';

angular.module('enervexSalesappApp').controller('ControlsCtrl', function($scope, Control, _) {
	$scope.allControls = [];
	$scope.offset = 0;
	$scope.limit = 50;
	function fetch() {
		Control.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(controls){
			if (controls.length > 0) {
				$scope.allControls = $scope.allControls.concat(controls);
				$scope.offset = $scope.offset + $scope.limit;
				fetch();
			} else {
				$scope.controls = _.sortBy($scope.allControls, function(control){
					return control.sortOrder
				})
			}
		});
	}
	fetch();
	$scope.delete = function(control) {
		Control.delete({
			id: control._id
		});
		$scope.allControls = _.filter($scope.allControls, function(r){
			return r._id != control._id
		})
		// $scope.applyFilter()
		$scope.controls = $scope.allControls;
	}
	// $scope.applyFilter = function() {
	// 	var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;

	// 	$scope.controls = _.filter($scope.allControls, function(rule){
	// 		if (nameReg && !(nameReg.test(rule.action) || nameReg.test(rule.filter) || (rule.product && nameReg.test(rule.product.name)) || nameReg.test(rule.legacyId) )) {
	// 			return false;
	// 		} else if ($scope.category && $scope.category != '' && $scope.category != rule.category) {
	// 			return false;
	// 		} else {
	// 			return true
	// 		}
	// 	})
	// }
});
